<template>
  <!-- 设备监测 -->
  <div class="monitor">
    <div class="tip">{{ keyList[this.indexSub].tip }}</div>
    <div class="content">
      <div class="left">
        <p>
          <img
            class="keyboard"
            src="../assets/image/other/keyboard1.png"
            alt=""
          />
          <!-- <span>键盘</span> -->
        </p>
        <img class="range" src="../assets/image/other/range1.png" alt="" />
      </div>
      <div class="right">
        <p>
          <img class="mouse" src="../assets/image/other/mouse1.png" alt="" />
          <!-- <span>鼠标</span> -->
        </p>
        <img class="range" src="../assets/image/other/range2.png" alt="" />
      </div>
    </div>
    <ul>
      <li
        v-for="(item, index) in keyList"
        :key="index"
        :class="{ active: item.isCheck }"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyList: [
        { name: 'P', code: '80', tip: '请在键盘按下P键', isCheck: false },
        { name: 'Q', code: '81', tip: '请在键盘按下Q键', isCheck: false },
        { name: '空格', code: '32', tip: '请在键盘按下空格键', isCheck: false },
        { name: '左键', code: null, tip: '请点击鼠标左键', isCheck: false },
        { name: '右键', code: null, tip: '请点击鼠标右键', isCheck: false },
      ],
      indexSub: 0,
    };
  },
  methods: {
    printKeyCode(e) {
      if (e.keyCode == this.keyList[this.indexSub].code) {
        this.keyList[this.indexSub].isCheck = true;
        this.indexSub++;
      }
    },
  },
  beforeDestroy() {
    document.onkeyup = '';
    document.onmousedown = '';
  },
  mounted() {
    //键盘事件
    document.onkeyup = (e) => {
      if (this.indexSub < this.keyList.length - 1) {
        this.printKeyCode(e);
      }
    };
    //阻止鼠标右键菜单弹出
    document.oncontextmenu = () => {
      return false;
    };
    document.onmousedown = (e) => {
      if (this.indexSub == 3 && e.button == 0) {
        this.keyList[this.indexSub].isCheck = true;
        this.indexSub++;
      }
      if (this.indexSub > 3 && e.button == 2) {
        this.keyList[this.indexSub].isCheck = true;
        this.$router.replace('/checkpoint');
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .monitor {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    .tip {
      width: 400px;
      height: 70px;
      line-height: 70px;
      color: #fb7e3c;
      font-size: 30px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 62px;
      background-image: url('../assets/image/other/tip_bg.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .content {
      margin: 0 auto;
      text-align: center;
      .left {
        display: inline-block;
        margin-right: 160px;
        .keyboard {
          width: 105px;
          height: 57px;
        }
        .range {
          width: 220px;
          height: 10px;
          margin-top: 16px;
        }
      }
      .right {
        display: inline-block;
        .mouse {
          width: 75px;
          height: 59px;
        }
        .range {
          width: 110px;
          height: 10px;
          margin-top: 16px;
        }
      }
      span {
        color: #5f9160;
        font-size: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-bottom: 5px;
        padding-left: 5px;
      }
    }
    ul {
      text-align: center;
      margin-top: -5px;
      li {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 20px;
        margin: 0 5px;
        font-weight: bold;
        color: #5f9160;
        display: inline-block;
        background-image: url('../assets/image/other/key.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      li:nth-child(3) {
        margin-right: 50px;
      }
      li:nth-child(4) {
        margin-left: 10px;
      }
      li.active {
        background-image: url('../assets/image/other/key_hover1.png');
        color: #ffffff;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .monitor {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    .tip {
      width: 520px;
      height: 91px;
      line-height: 91px;
      color: #fb7e3c;
      font-size: 39px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 80px;
      background-image: url('../assets/image/other/tip_bg.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .content {
      margin: 0 auto;
      text-align: center;
      .left {
        display: inline-block;
        margin-right: 208px;
        .keyboard {
          width: 137px;
          height: 74px;
        }
        .range {
          width: 286px;
          height: 13px;
          margin-top: 21px;
        }
      }
      .right {
        display: inline-block;
        .mouse {
          width: 98px;
          height: 72px;
        }
        .range {
          width: 143px;
          height: 13px;
          margin-top: 21px;
        }
      }
      span {
        color: #5f9160;
        font-size: 26px;
        vertical-align: bottom;
        display: inline-block;
        padding-bottom: 8px;
        padding-left: 8px;
      }
    }
    ul {
      text-align: center;
      margin-top: -8px;
      li {
        width: 130px;
        height: 130px;
        line-height: 130px;
        font-size: 26px;
        margin: 0 8px;
        font-weight: bold;
        color: #5f9160;
        display: inline-block;
        background-image: url('../assets/image/other/key.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      li:nth-child(3) {
        margin-right: 65px;
      }
      li:nth-child(4) {
        margin-left: 13px;
      }
      li.active {
        background-image: url('../assets/image/other/key_hover1.png');
        color: #ffffff;
      }
    }
  }
}
</style>
